<template>
  <div>
    <slot name="ProgressBar"/>
    <PageHeader
      :title="headerTitle"
      :subtitle="headerSubtitle"
    />

    <div class="ui-border-line ui-h-0 ui-mb-1"></div>

    <tag-box>
      <li
        v-for="(row, key) in timeArr"
        :key="key"
        @click="() => {
          if (row === '직접입력') $refs.EtcModal.show()
          else timeData = row
        }"
        :class="{ on: timeData === row }"
      >
        {{row}}
      </li>
    </tag-box>

    <modal
      ref="EtcModal"
      title="직접 입력해주세요"
      class="etc"
      :showCb="() => modalData.etc = formData.etc"
      confirm="등록"
      :confirmCb="modal => {
        formData.etc = modalData.etc
        timeData = '직접입력'
        modal.hide()
      }"
    >
      <div slot="body">
        <div class="ui-inputbox etc">
          <input type="text" v-model="modalData.etc" placeholder="직접 입력해주세요">
        </div>
      </div>
    </modal>

    <div class="ui-border-line ui-h-0 ui-mb-4"></div>

    <template v-if="isWeddingMcOffer">
      <p class="postOffer-guideLetter">
        선택 Tip<br />
        시간을 잘 모르겠다면?<br />
        직접입력에 <b>1부 예식, 2부 예식</b> 등으로 입력
      </p>

      <div class="ui-border-line ui-h-0 ui-mb-6"></div>
    </template>

    <footer-box
      submitText="다음"
      :submitCb="() => nextStep()"
      :submitDisabled="!validate"
    >
    </footer-box>
  </div>
</template>

<script>
import PageHeader from '@/components/common/PageHeader'
import TagBox from '@/components/common/TagBox'
import FooterBox from '@/components/common/FooterBox'
import Modal from '@/components/common/Modal'

export default {
  components: {
    PageHeader,
    TagBox,
    FooterBox,
    Modal,
  },
  data() {
    return {
      timeData: '',
      formData: {
        etc: '',
      },
      modalData: {
        etc: '',
      },
    }
  },
  computed: {
    step: {
      get() {
        return this.$route.params.step
      },
    },
    postOffer: {
      get() {
        return this.$store.state.postOffer
      },
      set(val) {
        this.$store.commit('setPostOffer', val)
      },
    },
    validate: {
      get() {
        return !this.timeData === false
      },
    },
    isWeddingMcOffer() {
      const condition = [
        () => /^(결혼식|주례없는 결혼식)$/.test(this.postOffer.eventType),
        () => this.postOffer.genre === 'MC',
      ]

      return condition.every((fn) => fn())
    },
    isWeddingSingOffer() {
      if (this.postOffer.genre !== '노래') {
        return false
      }

      if (!['결혼식', '주례없는 결혼식'].includes(this.postOffer.eventType)) {
        return false
      }

      return true
    },
    headerTitle() {
      if (this.isWeddingSingOffer) {
        return '원하는 곡수를 선택하세요.'
      }

      return `전문가는 몇 시간동안\n필요한가요?`
    },
    headerSubtitle() {
      if (this.isWeddingSingOffer) {
        return '축가는 1곡이 기본이고, 곡수에 따라 견적이 달라져요.'
      }

      return ''
    },
    timeArr() {
      if (this.isWeddingSingOffer) {
        return ['1곡', '2곡', '3곡', '직접입력']
      }

      return [ '30분', '1시간', '2시간', '3시간', '6시간', '8시간', '1일', '직접입력']
    },
  },
  beforeMount() {
    this.init()
  },
  methods: {
    init() {
      let timeData = this.$store.state.postOffer.playTime
      let etc = ''

      if (timeData && !this.timeArr.includes(timeData)) {
        etc = timeData
        timeData = '직접입력'
      }

      this.timeData = timeData
      this.formData.etc = etc
    },
    nextStep() {
      const playTime = this.timeData !== '직접입력' ? this.timeData : this.formData.etc
      this.postOffer = { playTime }
      this.$parent.nextStep()
    },
  },
}
</script>

<style lang="scss" scoped>
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._t("ProgressBar"),
      _c("PageHeader", {
        attrs: { title: _vm.headerTitle, subtitle: _vm.headerSubtitle }
      }),
      _c("div", { staticClass: "ui-border-line ui-h-0 ui-mb-1" }),
      _c(
        "tag-box",
        _vm._l(_vm.timeArr, function(row, key) {
          return _c(
            "li",
            {
              key: key,
              class: { on: _vm.timeData === row },
              on: {
                click: function() {
                  if (row === "직접입력") {
                    _vm.$refs.EtcModal.show()
                  } else {
                    _vm.timeData = row
                  }
                }
              }
            },
            [_vm._v(" " + _vm._s(row) + " ")]
          )
        }),
        0
      ),
      _c(
        "modal",
        {
          ref: "EtcModal",
          staticClass: "etc",
          attrs: {
            title: "직접 입력해주세요",
            showCb: function() {
              return (_vm.modalData.etc = _vm.formData.etc)
            },
            confirm: "등록",
            confirmCb: function(modal) {
              _vm.formData.etc = _vm.modalData.etc
              _vm.timeData = "직접입력"
              modal.hide()
            }
          }
        },
        [
          _c("div", { attrs: { slot: "body" }, slot: "body" }, [
            _c("div", { staticClass: "ui-inputbox etc" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.modalData.etc,
                    expression: "modalData.etc"
                  }
                ],
                attrs: { type: "text", placeholder: "직접 입력해주세요" },
                domProps: { value: _vm.modalData.etc },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.modalData, "etc", $event.target.value)
                  }
                }
              })
            ])
          ])
        ]
      ),
      _c("div", { staticClass: "ui-border-line ui-h-0 ui-mb-4" }),
      _vm.isWeddingMcOffer
        ? [
            _vm._m(0),
            _c("div", { staticClass: "ui-border-line ui-h-0 ui-mb-6" })
          ]
        : _vm._e(),
      _c("footer-box", {
        attrs: {
          submitText: "다음",
          submitCb: function() {
            return _vm.nextStep()
          },
          submitDisabled: !_vm.validate
        }
      })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "postOffer-guideLetter" }, [
      _vm._v(" 선택 Tip"),
      _c("br"),
      _vm._v(" 시간을 잘 모르겠다면?"),
      _c("br"),
      _vm._v(" 직접입력에 "),
      _c("b", [_vm._v("1부 예식, 2부 예식")]),
      _vm._v(" 등으로 입력 ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }